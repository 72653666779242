/**
 * This class supports fetching the runtime configuration for a PHAF application and
 * MFEs that compose it. It ensures that a configuration for each MFE is loaded
 * before the MFE itself is loaded, thus making the configuration available throughout
 * the MFE's lifecycle.
 * To use this class
 * - Call 'setup' from your root configuration. This should be done as early as possible.
 * - Call 'get' from your root configuration or MFE anywhere you need to use a configuration value.
 *
 * The complexity of this class is due to the fact that we need to load the configuration
 * from a deployed environment (everything deployed), a local environment (everything local),
 * or a local environment where some MFEs are deployed (some local, some deployed).
 *
 */
class RuntimeConfiguration {
  private urlToModuleName: Map<string, string> = new Map();
  private localProxyUrl = 'http://localhost:5000';

  constructor(private configurations: Map<string, Record<string, unknown>>) {}

  /**
   * Retrieves origin from a complex URL: https://developer.mozilla.org/en-US/docs/Web/API/URL/origin.
   */
  private getUrlOrigin(fullUrl: string): string {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const url = new URL(fullUrl);

    return url.origin;
  }

  /**
   * Returns whether the url is a local URL. Used to check if we should fetch configuration from local MFE.
   */
  private isLocalUrl(url: string): boolean {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const urlObj = new URL(url);

    return urlObj.hostname === 'localhost';
  }

  /**
   * Returns whether the url is a local proxy URL. Used to check if we should fetch configuration from local proxy.
   */
  private isLocalProxyUrl(url: string): boolean {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const urlObj = new URL(url);

    return urlObj.origin === this.localProxyUrl;
  }

  /**
   * Checks if module follows expected PHAF format (prefixed with @verily-src/)
   */
  private isPHAFPackage(moduleName: string): boolean {
    return moduleName.startsWith('@verily-src/');
  }

  /**
   * Makes request for MFE's configuration, and adds it to the configuration map.
   * Handles two distinct cases:
   * 1. Request is not-proxied, goes directly to local webpack devServer.
   * 2. Reqeust is proxied, requires formatting for proxy server to fetch config. Handles local and deployed proxy.
   * Note in the above cases a root config Configuration is loaded the same as an MFE Configuration.
   *
   * @param moduleName - the name of the module to fetch configuration for.
   * @param moduleOrigin - the URL origin which a module is loaded from. e.g. http://localhost:1234
   */
  private async fetchConfiguration(moduleName: string, moduleOrigin: string) {
    let response: Response;

    try {
      if (
        this.isLocalUrl(moduleOrigin) &&
        !this.isLocalProxyUrl(moduleOrigin)
      ) {
        // Case 1: request goes to webpack devserver
        response = await fetch(`${moduleOrigin}/config-local.json`);
      } else {
        // Case 2: request is proxied
        response = await fetch(
          `${moduleOrigin}/mfe/${moduleName}/?file=config.json`
        );
      }

      const config = await response.json();
      this.configurations.set(moduleName, config);
    } catch (error) {
      // Ignore errors fetching configuration.
      // TODO (PHP-14574): Indicate if a configuration is missing.
    }
  }

  /**
   * Removes @verily-src/ prefix and anything beyond following slash.
   * e.g., @verily-src/clinical-data-app -> clinical-data-app
   */
  private packageNameToModuleName(packageName: string): string {
    const moduleName = packageName.replace(/^@verily-src\//, '').split('/')[0];

    return moduleName.toString();
  }

  /**
   * Registers a hook that sets "url": "moduleName" in urlToModuleNameMap.
   * Used for reverse lookups when determining where to fetch a config from.
   */
  private addHookToBuildUrlToModuleNameMap(
    overriddenMfeNames: Record<string, string> = {}
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const classInstance = this;
    const existingHook = System.constructor.prototype.resolve;

    System.constructor.prototype.resolve = async function (
      id: string,
      parentUrl: string
    ) {
      const url = await existingHook.call(this, id, parentUrl);

      // We only need to fetch configuration for PHAF modules, not shared external modules.
      if (classInstance.isPHAFPackage(id)) {
        const moduleName =
          overriddenMfeNames[id] ?? classInstance.packageNameToModuleName(id);
        classInstance.urlToModuleName.set(url, moduleName);
      }
      return url;
    };
  }

  /**
   * Updates the SystemJS instantiate hook to load configuration before entrypoint.
   * SystemJS Hooks documentation: https://github.com/systemjs/systemjs/blob/main/docs/hooks.md#loader-hooks
   */
  private addPreLoadConfigHook() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const classInstance = this;
    const existingHook = System.constructor.prototype.instantiate;

    System.constructor.prototype.instantiate = async function (
      url: string,
      parentUrl: string
    ) {
      const moduleName = classInstance.urlToModuleName.get(url) ?? '';

      if (moduleName) {
        await classInstance.fetchConfiguration(
          moduleName,
          classInstance.getUrlOrigin(url)
        );
      }
      return existingHook.call(this, url, parentUrl);
    };
  }

  /**
   * Gets configuration for a given MFE. Returns null when config not found.
   * @param mfeName - the name of the MFE to get configuration for.
   * @param key - the key of the configuration to get.
   */
  get(mfeName: string, key: string): unknown | null {
    const mfeConfig = this.configurations.get(mfeName);
    if (!mfeConfig) {
      return null;
    }
    return mfeConfig[key];
  }

  /**
   *  Sets up hooks to fetch configurations before entrypoint. All MFEs will use
   *  these hooks when loading to get their configuration.
   */
  async setup(overriddenMfeNames: Record<string, string> = {}): Promise<void> {
    // Register hook to build urlToModuleNameMap.
    this.addHookToBuildUrlToModuleNameMap(overriddenMfeNames);
    // Register hook to load configuration before entrypoint. Requires urlToModuleNameMap.
    this.addPreLoadConfigHook();
  }
}

export {RuntimeConfiguration};
